







import { Component, Prop, Vue } from "vue-property-decorator";

import AuthenticationForm from "@/settings/components/networks-management/AuthenticationForm.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";

@Component({
  components: {
    AuthenticationForm,
  },
})
export default class AuthenticationView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;
}
