







import { Component, Vue, Prop } from "vue-property-decorator";

import DataRequestForm from "./DataRequestForm.vue";

@Component({
  components: {
    DataRequestForm,
  },
})
export default class AuthenticationForm extends Vue {
  @Prop() activeTab!: string;
  @Prop() networkIntegrationId!: number;
}
